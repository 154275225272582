<template>
  <v-app>
    <v-container fluid>
        <body class="application application-offset">
            <div class="container-fluid container-application">
                <mainMenu /> <!-- ==== 左選單 ==== -->

                <div class="main-content position-relative">
                    <mainNav /> <!-- ==== Top選單 ==== -->

                    <div class="page-content index">
        
                        <!-- Page title -->
                        <div class="page-title">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-md-6 mb-3 mb-md-0" style="z-index:5">
                                    <h5 class="h3 font-weight-400 mb-0 text-white">嗨，{{userName}}!</h5>
                                    <span class="text-sm text-white opacity-8">祝你有個美好的一天!</span>
                                </div>
                            </div>
                        </div>

                        <div class="card" style="z-index: 2;">
                          <div class="card-header actions-toolbar border-0">
                            <div class="row justify-content-between align-items-center">
                                <div class="col" style="padding-top: 0px;padding-bottom: 0px;">
                                    <h2 class="d-inline-block mb-0 card-title">事業單位維護</h2>
                                </div>
                            </div>
                          </div>
                          <br/>
                            <!-- ==========主要內容區========== -->
                            <v-form ref="form" class="px-12" v-model="isValid">
                              <v-row>
                                <v-subheader>
                                  事業單位基本資料
                                </v-subheader>
                              </v-row>        
                              <v-row>
                                <v-divider class="my-0"></v-divider>
                              </v-row>   
                              <v-row>
                                <v-col sm="2" style="margin-bottom: 0px;">
                                  <v-switch
                                    v-model="orgData.status"
                                    label="啟用"
                                    color="success"
                                    hide-details
                                    :readonly = "this.loginAuth.role !== 'S'"
                                  ></v-switch>
                                </v-col>
                                <v-col>
                                  <v-text-field label="*事業單位名稱(必填)" outlined v-model="orgData.name" :rules="[rules.required]" required  hint="*必填，若同一事業單位申請多組，可於事業單位名稱加註文字識別。"  persistent-hint :readonly = "this.loginAuth.role !== 'S'"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col sm="4">
                                  <v-text-field label="聯絡人姓名" outlined v-model="orgData.contactName"></v-text-field>
                                </v-col>
                                <v-col sm="4">
                                  <v-text-field label="電話" outlined v-model="orgData.phone"></v-text-field>
                                </v-col>
                                <v-col sm="4">
                                  <v-text-field label="*統一編號(必填)"  type="text" maxlength="8" outlined v-model="orgData.idn" :rules="[rules.required, rules.orgNum]" required hint="*必填"  persistent-hint :readonly = "this.loginAuth.role !== 'S'"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col sm="3">
                                  <v-select
                                    v-model="orgData.county"
                                    :items="citylist"
                                    label="縣市"
                                    outlined
                                    item-text="name"
                                  ></v-select>
                                </v-col>
                                <v-col>
                                  <v-text-field label="地址" outlined v-model="orgData.address"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field label="email" outlined v-model="orgData.email"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row v-if="this.$route.params.orgId !== 'init'">
                                <v-col>
                                  <v-subheader>
                                    子單位
                                  </v-subheader>
                                </v-col>
                                <v-col sm="1">
                                  <v-btn
                                    class="ma-2"
                                    text
                                    icon
                                    color="#37906C"
                                    @click="addSubUnitData"
                                  >
                                     <v-icon large class="mt-1">mdi-plus-box</v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>        
                              <v-row v-if="this.$route.params.orgId !== 'init'">
                                <v-divider class="my-0"></v-divider>
                              </v-row>
                              <v-row v-if="this.$route.params.orgId !== 'init'">
                                <v-col>
                                  <v-data-table
                                  :headers="tHeaders"
                                  :items="subUnitList"
                                  hide-default-footer
                                  hide-default-header
                                  disable-sort
                                  disable-pagination>
                                    <template v-slot:[`item.suName`]={item}>
                                      <v-text-field v-model="item.suName" label="子單位名稱" outlined hide-details dense :readonly="!item.editStatus"></v-text-field>
                                    </template>
                                    <template v-slot:[`item.act`]={item}>
                                      <v-btn class="mr-4 white--text" color="#37906C" v-if="item.editStatus" @click="updateUnitData(item)">
                                        儲存子單位
                                      </v-btn>
                                      <v-btn class="mr-4 white--text" color="#4D4D4D" v-if="item.editStatus" @click="cancelUnitData()">
                                        取消
                                      </v-btn>
                                      <v-btn class="mr-4 white--text" color="#37906C" v-if="!item.editStatus" @click="editUnitData(item)">
                                        編輯子單位
                                      </v-btn>
                                      <v-btn class="mr-4 white--text" color="#4D4D4D" v-if="!item.editStatus" @click="deleteUnitData(item)">
                                        刪除子單位
                                      </v-btn>
                                    </template>
                                  </v-data-table>
                                </v-col>
                              </v-row>                              
                              <v-row>
                                <v-subheader>
                                  服務設定
                                </v-subheader>
                              </v-row>        
                              <v-row>
                                <v-divider class="my-0"></v-divider>
                              </v-row>                      
                              <v-row>
                                <v-col sm="6">
                                  <v-text-field label="場域管理者帳號數量上限" outlined v-model="orgData.siteManagerLimit" :readonly="loginAuth.role !=='S'"></v-text-field>
                                </v-col>
                                <v-col sm="6">
                                  <v-text-field label="事業單位人員數量上限" outlined v-model="orgData.orgMemberLimit" :readonly="loginAuth.role !=='S'"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col sm="6">
                                  <v-text-field label="場域數量上限" outlined v-model="orgData.siteLimit" :readonly="loginAuth.role !=='S'"></v-text-field>
                                </v-col>
                                <v-col sm="6">
                                  <v-text-field label="發送通知簡訊數量上限" outlined v-model="orgData.messageLimit" :readonly="loginAuth.role !=='S'"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col
                                  cols="12"
                                  sm="6"
                                  md="4"
                                >
                                  <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="allowDateRange"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="dateRange"
                                        label="服務期間"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="allowDateRange"
                                      no-title
                                      scrollable
                                      range
                                      :readonly="loginAuth.role !== 'S'"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="menu = false"
                                        v-if="loginAuth.role === 'S'"
                                      >
                                        Cancel
                                      </v-btn>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu.save(allowDateRange)"
                                        v-if="loginAuth.role === 'S'"
                                      >
                                        OK
                                      </v-btn>
                                    </v-date-picker>
                                  </v-menu>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-subheader>
                                  加值服務設定
                                </v-subheader>
                              </v-row>        
                              <v-row>
                                <v-divider class="my-0"></v-divider>
                              </v-row>  
                              <v-row>
                                <v-col>
                                  <v-switch
                                    v-model="orgData.oscInterfaceEnable"
                                    label="臺灣職安卡介接資料"
                                    color="success"
                                    hide-details
                                    :readonly = "loginAuth.role !=='S'"
                                  ></v-switch>
                                </v-col>
                              </v-row>
                              <v-row v-if="orgData.oscInterfaceEnable">
                                <v-col sm="4">
                                  <v-text-field label="事業單位帳號" outlined v-model="orgData.oscInterfaceAccount" :readonly = "loginAuth.role !=='S'"></v-text-field>
                                </v-col>
                                <v-col sm="4">
                                  <v-text-field label="認證碼" outlined v-model="orgData.oscInterfaceVerifycode" :readonly = "loginAuth.role !=='S'"></v-text-field>
                                </v-col>
                                <v-col sm="4">
                                  <v-text-field label="工地編號" outlined v-model="orgData.oscInterfaceSiteID" :readonly = "loginAuth.role !=='S'"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-switch
                                    v-model="orgData.heatindexEnable"
                                    label="熱指數儀表板"
                                    color="success"
                                    hide-details
                                    :readonly = "loginAuth.role !=='S'"
                                  ></v-switch>
                                </v-col>
                                <v-col>
                                  <v-switch
                                    v-model="orgData.thEnable"
                                    label="溫濕度儀表板"
                                    color="success"
                                    hide-details
                                    :readonly = "loginAuth.role !=='S'"
                                  ></v-switch>
                                </v-col>
                                <v-col>
                                  <v-switch
                                    v-model="orgData.keepworkAlertEnable"
                                    label="連續七日入場警示"
                                    color="success"
                                    hide-details
                                    :readonly = "loginAuth.role !=='S'"
                                  ></v-switch>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-switch
                                    v-model="orgData.smartPlugEnable"
                                    label="智慧插座燈號"
                                    color="success"
                                    value="Y"
                                    hide-details
                                    :readonly = "loginAuth.role !=='S'"
                                  ></v-switch>
                                </v-col>
                                <v-col>
                                  <v-switch
                                    v-model="orgData.voiceAlarmEnable"
                                    label="語音提示"
                                    color="success"
                                    hide-details
                                    :readonly = "loginAuth.role !=='S'"
                                  ></v-switch>
                                </v-col>
                                <v-col>
                                   <v-switch
                                    v-model="orgData.visitorEnable"
                                    label="訪客功能"
                                    color="success"
                                    hide-details
                                    :readonly = "loginAuth.role !=='S'"
                                  ></v-switch>
                                </v-col>
                              </v-row>
                              <v-row v-if="orgData.smartPlugEnable=='Y'">
                                <v-col sm="6">
                                  <v-text-field label="成功網址" outlined v-model="orgData.smartPlugSuccessUrl" :readonly = "loginAuth.role !=='S'"></v-text-field>
                                </v-col>
                                <v-col sm="6">
                                  <v-text-field label="失敗網址" outlined v-model="orgData.smartPlugFailureUrl" :readonly = "loginAuth.role !=='S'"></v-text-field>
                                </v-col>
                              </v-row>
                            
                              <v-row justify="center">
                                    <v-btn class="mr-4 white--text" min-width="100" color="#37906C" large @click="submitData" :disabled="saveStatus || !isValid">
                                      儲存
                                    </v-btn>
                                    <v-btn class="mr-4 white--text" color="#4D4D4D" large @click="toCancel">
                                      回上一頁
                                    </v-btn>
                              </v-row>
                            </v-form>
                            <!-- ==========主要內容區========== -->
                            <br/>
                        </div>
                    </div>

                    <mainFooter /><!-- Footer -->
                    
                </div>
            </div>
        </body>
        <loadingMask :showLoading="isLoading" style="z-index:9999"></loadingMask>

    </v-container>
  </v-app>
</template>

<script>
import mainMenu from '@/components/mainMenu.vue'
import mainNav from '@/components/mainNav.vue'
import mainFooter from '@/components/mainFooter.vue'
import commonMixin from '@/utils/commonMixin';
import loadingMask from '@/components/public/loadingMask.vue'
import {getOrgnizationData, saveOrgnization, addOrgnization, addSubUnit, saveSubUnit, delSubUnit} from "@/api/hyirecoApi";

export default {
  name: 'orgnizationList',
  mixins: [commonMixin],
  created(){
    this.authValidate();
    console.log("loginAuth = " + JSON.stringify(this.loginAuth));
    this.auth = {
      headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getTokenWithSession(),
      }
    };
  },
  destroyed() {
    clearInterval(this.interval);
  },
  computed: {
    tHeaders() {
      return [
          {
            text: '名稱',
            align: 'start',
            value: 'suName',
            class: "subtitle-2 font-weight-black",
            width: "500",
          },
          { text: '動作', value: 'act', class: "subtitle-2 font-weight-black", align: 'start',width:"100"},
      ]
    },
    dateRange:function(){
      let vm = this;
      console.log(vm.allowDateRange);
      if (vm.allowDateRange.length === 2) {
        console.log((Date.parse(vm.allowDateRange[0])).valueOf());
        console.log((Date.parse(vm.allowDateRange[1])).valueOf());
        if ( (Date.parse(vm.allowDateRange[0])).valueOf() > (Date.parse(vm.allowDateRange[1])).valueOf())
        {
          var tmpDate = vm.allowDateRange[0];
          vm.allowDateRange[0] = vm.allowDateRange[1];
          vm.allowDateRange[1] = tmpDate;
          
        }
        return vm.allowDateRange[0] + "~" + vm.allowDateRange[1];
      }
      return "";
    },
  },
  mounted(){
    this.userName = this.loginAuth.memberName;
    this.citylist =  this.getCodeDateUtil("district");
    if (this.$route.params.orgId === "init") { 
      this.orgData.status = true;
      this.orgData.siteManagerLimit = "0";
      this.orgData.orgMemberLimit = "0";
      this.orgData.siteLimit = "0";
      this.orgData.messageLimit = "0";
    } else {
      this.getOrgData();
    } 
  },
  methods: {
    getOrgData: function(){
      this.isLoading = true;
      var vm = this;
      getOrgnizationData(vm.$route.params.orgId, vm.auth).then(function(response) {
          if ("200" == response.status) { 
              var responseData = response.data;
              console.log("responseData = " + JSON.stringify(responseData));
              vm.orgData = responseData.orgnization;
              vm.orgData.status = vm.orgData.status === "Y" ? true : false;
              vm.orgData.heatindexEnable = vm.orgData.heatindexEnable === "Y" ? true : false;
              vm.orgData.thEnable = vm.orgData.thEnable === "Y" ? true : false;
              vm.orgData.keepworkAlertEnable = vm.orgData.keepworkAlertEnable === "Y" ? true : false;
              //console.log("vm.orgData.voiceAlarmEnable = " + vm.orgData.voiceAlarmEnable);
              vm.orgData.voiceAlarmEnable = vm.orgData.voiceAlarmEnable === "Y" ? true : false;
              vm.orgData.oscInterfaceEnable = vm.orgData.oscInterfaceEnable === "Y" ? true : false;
              vm.orgData.visitorEnable = vm.orgData.visitorEnable === "Y" ? true : false;
              
              if (typeof vm.orgData.serviceStartDate !== "undefined") {
                vm.allowDateRange.push(vm.orgData.serviceStartDate.replace(/\//g,"-"));
              }

              if (typeof vm.orgData.serviceEndDate !== "undefined") {
                vm.allowDateRange.push(vm.orgData.serviceEndDate.replace(/\//g,"-"));
              }

              vm.subUnitList = responseData.orgnization.subUnitList.map(function(item){
                item.editStatus = false;
                return item
              }); 



              //console.log("subUnitList = " + JSON.stringify(vm.subUnitList));
          }
      });
      this.isLoading = false;
    },
    submitData: function() {
      var vm = this;
      vm.isLoading = true;
      vm.saveStatus = true;

      var apiObj = saveOrgnization;
      if (this.$route.params.orgId === "init") { 
        apiObj = addOrgnization;
      }

      vm.orgData.status = vm.orgData.status ? "Y" : "N";
      vm.orgData.heatindexEnable = vm.orgData.heatindexEnable ? "Y" : "N";
      vm.orgData.thEnable = vm.orgData.thEnable ? "Y" : "N";
      vm.orgData.keepworkAlertEnable = vm.orgData.keepworkAlertEnable ? "Y" : "N";
      vm.orgData.voiceAlarmEnable = vm.orgData.voiceAlarmEnable ? "Y" : "N";
      vm.orgData.oscInterfaceEnable = vm.orgData.oscInterfaceEnable ? "Y" : "N";
      vm.orgData.visitorEnable = vm.orgData.visitorEnable ? "Y" : "N";

      if (vm.allowDateRange.length === 2) {
        vm.orgData.serviceStartDate = vm.allowDateRange[0];
        vm.orgData.serviceEndDate = vm.allowDateRange[1];
      }
      
      var dataObj = new Object();
      dataObj.orgnization = vm.orgData;

      console.log("dataObj = " + JSON.stringify(dataObj));
      apiObj(dataObj, vm.auth).then(function(response) {
          if ("200" == response.status) { 
            var responseData = response.data;
            vm.$swal(responseData.message);
            if (responseData.statusCode === "1") {
              vm.toCancel();
            }
            //console.log("responseData = " + JSON.stringify(response));
            vm.saveStatus = false;
            vm.isLoading = false;
          }
      });
    },
    toCancel: function () {
      var vm = this;
      var path = "";
      var next = vm.$route.meta.authority.filter(function(item){
        return item.role === vm.loginAuth.role;
      });

      if (next.length > 0) {
        path = next[0].returnPath;
      } else {
        path = "/index";
      }

      this.$router.push({ path: path });
    },
    addSubUnitData: function(){
      var vm = this;

      if (vm.addSubUnitStatus) { //先判斷是否有其他子單位在編輯中
        vm.addSubUnitStatus = false;
        vm.oldSubUnitList = JSON.parse(JSON.stringify(vm.subUnitList));
        var objUnit = new Object();
        objUnit.suName = "";
        objUnit.orgId = vm.$route.params.orgId;
        objUnit.suId = "";
        objUnit.editStatus = true; //用於判斷介面上按鈕的顯示方式
        vm.subUnitList.push(objUnit);
        //console.log("vm.subUnitList = " + JSON.stringify(vm.subUnitList));
        //console.log("vm.oldSubUnitList = " + JSON.stringify(vm.oldSubUnitList));
      } else {
        this.$swal("已有新增或編輯中的子單位，請先儲存。");
      }
    },
    editUnitData: function(obj) {
      var vm = this;
      if (vm.addSubUnitStatus) {
        vm.addSubUnitStatus = false;
        vm.oldSubUnitList = JSON.parse(JSON.stringify(vm.subUnitList));
        vm.subUnitList = vm.subUnitList.map(function(item){
          if (obj.suId === item.suId) item.editStatus = true;
          return item;
        });
      } else {
        this.$swal("已有新增或編輯中的子單位，請先儲存。");
      }
    },
    updateUnitData: function(item) {
      var vm = this;
      vm.isLoading = true;

      if (item.suName.trim() === "") {
        this.$swal("請輸入子單位名稱");
        return;
      }

      var unitApi = addSubUnit;
      item.suName = item.suName.trim();
      if (item.suId !== "") {
        unitApi = saveSubUnit;
      }

      var dataObj = new Object();
      dataObj.subUnit = item;

      unitApi(dataObj, vm.auth).then(function(response) {
          if ("200" == response.status) { 
              var responseData = response.data;
              //console.log("responseData = " + JSON.stringify(responseData));

              //用於新增
              if (responseData.subUnit !== null) {
                vm.subUnitList = vm.subUnitList.map(function(value){
                  if (value.suId === "") value.suId = responseData.subUnit.suId;
                  return value;
                }); 
              }
              
              vm.subUnitList = vm.subUnitList.map(function(obj){
                if (obj.suId === item.suId) obj.editStatus = false;
                return obj;
              });

              console.log("vm.subUnitList = " + JSON.stringify(vm.subUnitList));
              console.log(vm.loginAuth.orgnization);
              if (typeof vm.loginAuth.orgnization != "undefined") {
                vm.loginAuth.orgnization.subUnitList = vm.subUnitList;
                window.sessionStorage.setItem('LoginAuth', JSON.stringify(vm.loginAuth)); 
              }
              
              vm.addSubUnitStatus = true;
          }
      });
      vm.isLoading = false;
    },
    cancelUnitData: function() {
      var vm = this;
      vm.subUnitList = JSON.parse(JSON.stringify(vm.oldSubUnitList));
      vm.addSubUnitStatus = true;
    },
    deleteUnitData: function(obj) {
      var vm = this;
      if (vm.addSubUnitStatus) {
        vm.$swal({
            title: "<font face='Microsoft JhengHei'>請確認</font>",
            html: "<font face='Microsoft JhengHei'>確認後將立即刪除 " + obj.suName + "</font>",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "<font face='Microsoft JhengHei' color='#fff'>確認</font>",
            cancelButtonText: "<font face='Microsoft JhengHei' color='#fff'>取消</font>",
            confirmButtonClass: "btn btn-success btn-fill",
            cancelButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false
        }).then(function(result) {
            if (result.isConfirmed) {
                vm.isLoading = true;
                var suId = obj.suId;

                delSubUnit(suId, vm.auth).then(function(response) {
                    vm.isLoading = false;
                    if ("200" == response.status) { 
                        var responseData = response.data;
                        //console.log("response = " + JSON.stringify(responseData));
                        vm.$swal(responseData.message);

                        vm.subUnitList = vm.subUnitList.filter(function(item){
                          return item.suId !== suId;
                        });
                        if (typeof vm.loginAuth.orgnization != "undefined") {
                          vm.loginAuth.orgnization.subUnitList = vm.subUnitList;
                          window.sessionStorage.setItem('LoginAuth', JSON.stringify(vm.loginAuth)); 
                        }
                    }
                });    
            }
        });
      } else {
        this.$swal("已有新增或編輯中的子單位，請先儲存。");
      }
    },
    
  },
  watch: {
    
  },
  data: () => ({
    rules: {
      required: value => !!value || '必填欄位',
      requiredForLabor: value => !!value || '若沒有請填 無',
      orgNum: value => {
        const pattern = /^\d{8}/
        return pattern.test(value) || '不符規格的統一編號'
      },
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || '不符規格的 e-mail 信箱'
      },
    },
    userName: "",
    orgData: {},
    isLoading: false,
    auth: {},
    saveStatus: false,
    isValid: true,
    subUnitList:[],
    addSubUnitStatus: true, //用於判斷子單位是否在新增或編輯狀態
    oldSubUnitList: [], //保留子單位在新增或編輯前的資料
    citylist: null,
    allowDateRange: [],
    menu:"",
  }),
  components: {
    mainMenu,
    mainNav,
    mainFooter,
    loadingMask,
  },
}
</script>

<style scoped>
.v-input__slot > .v-label
{
    margin-bottom: 0px;
}
.v-subheader {
    color: rgb(34, 34, 34) !important;
    font-size: large;
}
</style>